// require('./bootstrap');

import React from 'react';
import { render } from 'react-dom';
import { createInertiaApp } from '@inertiajs/inertia-react';
import { InertiaApp } from '@inertiajs/inertia-react'
import { InertiaProgress } from '@inertiajs/progress';
// import * as Sentry from '@sentry/browser';
import moment from 'moment';

moment.locale('th');

InertiaProgress.init({
    color: '#ED8936',
    showSpinner: true
});

// createInertiaApp({
//     id: 'app',
//     resolve: name => import(`./Pages/${name}`),
//     setup({ el, App, props }) {
//         render(<App {...props} />, el);
//     },
//     // output: {
//     //     chunkFilename: 'js/[name].js?id=[chunkhash]',
//     // }
// });

// Sentry.init({
//     dsn: process.env.MIX_SENTRY_LARAVEL_DSN
// });

const app = document.getElementById('app');

render(
    <InertiaApp
      initialPage={JSON.parse(app.dataset.page)}
      resolveComponent={name =>
        import(`./Pages/${name}`).then(module => module.default)
      }
    />,
    app
);
